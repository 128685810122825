var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-app": "" } },
    [
      _c(
        "CToaster",
        [
          _c(
            "Toast",
            {
              attrs: {
                color: _vm.toast.color,
                header: _vm.toast.header,
                autohide: _vm.toast.autohide,
              },
              model: {
                value: _vm.toast.showToast,
                callback: function ($$v) {
                  _vm.$set(_vm.toast, "showToast", $$v)
                },
                expression: "toast.showToast",
              },
            },
            [_vm._v(" " + _vm._s(_vm.toast.toastMessage) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.$store.state.isLoading, "z-index": "5000" } },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", { attrs: { indeterminate: "" } }),
              _c(
                "div",
                { staticStyle: { padding: "10px", "margin-left": "5px" } },
                [_vm._v("Please Wait...")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }