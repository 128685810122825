import { cibOpenstreetmap } from '@coreui/icons';
import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
// const store = require('../store')

// Containers
const TheContainer = () => import( '@/containers/TheContainer' );

// Views
const Dashboard = () => import( '@/views/Dashboard' );

//Rules
const SearchRules = () => import( '@/views/rules/SearchRules' );
const EditRules = () => import( '@/views/rules/EditRules' );

// Views - Pages

const Page401 = () => import( '@/views/pages/Page401' );
const Page404 = () => import( '@/views/pages/Page404' );
const Page500 = () => import( '@/views/pages/Page500' );
const Login = () => import( '@/views/pages/Login' );

const UserManagement = () => import ('@/views/admin/UserManagement')


Vue.use( Router );

const router = new Router( {
  mode:            'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior:  () => ( { y: 0 } ),
  routes:          configRoutes(),
} );

function configRoutes() {
  const routes = [
    {
      path:      '/',
      redirect:  '/home',
      name:      'Home',
      component: TheContainer,
      children:  [
        {
          path:      'login',
          name:      'Login',
          component: Login,
          meta:      {
            noAuth: true,
          },
        },
        {
          path:      'users',
          name:      'Users',
          component: UserManagement,
         
        }, 
        {
          path:      'home',
          name:      '',
          component: Dashboard,
        },
        {
          path:      'rules',
          redirect:  '/rules/search',
          name:      'Rules',
          component: {
            render( c ) {
              return c( 'router-view' );
            },
          },
          children: [
            {
              path:      'search',
              name:      'Search',
              component: SearchRules,
            },
            {
              path: 'edit',
              name: 'Edit',

              component: EditRules,
            },
          ],
        },
      ],
    },
    {
      path:      '/pages',
      redirect:  '/pages/404',
      name:      'Pages',
      component: {
        render( c ) {
          return c( 'router-view' );
        },
      },
      children: [
        {
          path:      '404',
          name:      'Page404',
          component: Page404,
        },
        {
          path:      '401',
          name:      'Page401',
          component: Page401,
        },
        {
          path:      '500',
          name:      'Page500',
          component: Page500,
        },

      ],
    },
    //catchall rerouting
    {
      path:      '*',
      component: Page404,
    },
  ];
/**
 * Disabling routes on sign in
 * This way route and files are not going to be linked to routes if user does not have access to it.
 */

  //in case impact is disabled, do not load the route or component at all.
  //TODO: there is a race condition which leads to duplicated code that is stored in the Vuex Store.
  //disabling impact workbench is already a getter in store. however cannot be used here. need better solution.
  const disable_impact = process.env.DISABLE_IMPACT_WORKBENCH !== undefined && process.env.DISABLE_IMPACT_WORKBENCH.toLowerCase() === 'true';
  if(store){

    console.log(store)
  }
  else{
    console.log('no store')
  }
  const show_admin = false
  if(show_admin){
     //find the index of the main route and add impact to children in order to be nested with navigations.
     const home_index = routes.findIndex( route => route.path == '/' );
     routes[ home_index ].children.push(
 
     )
  }
  if( !disable_impact ) {
    //find the index of the main route and add impact to children in order to be nested with navigations.
    const home_index = routes.findIndex( route => route.path == '/' );

    routes[ home_index ].children.push(
      {
        path:      '/impact',
        name:      'Impact Analysis',
        component: () => import( '@/views/allocations/ImpactAnalysis' ),
      },
    );
  }

  return routes;
}

// Navigation Guards

//Authentication guard
router.beforeEach( ( to, from, next ) => {
  const isAuthenticated = store.getters.isLoggedIn;
  const isAdmin = store.getters.isAdmin;
  const { noAuth } = to.meta;

  //if going to admin territory check if user is admin
  if(to.path === '/users' && !isAdmin){
    next('/pages/401')
  }
  //TODO: check the time expired to check if the token is still valid
  if( !noAuth && !isAuthenticated && to.name !== 'Login' ) {
    // Not authenticated: redirect the user to the login page
    next( '/login' );
  } else {
    // proceed to the next page normally.
    next();
  }
} );

export default router;
