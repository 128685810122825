<template>
  <div data-app>
    <CToaster>
      <Toast v-model="toast.showToast" :color="toast.color" :header="toast.header" :autohide="toast.autohide">
        {{ toast.toastMessage }}
      </Toast>
    </CToaster>
    <v-overlay :value="$store.state.isLoading" z-index="5000">
      <div class="text-center">
        <v-progress-circular indeterminate />

        <div style="padding: 10px; margin-left: 5px">Please Wait...</div>
      </div>
    </v-overlay>

    <router-view></router-view>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { bus } from './main';
import Toast from './shared/components/Toast.vue';
import RestClient from './services/RestClient';
import constants from './constants';

export default {
  name: 'App',
  created() {
    bus.$on( 'toast-success', ( header, toastMessage ) => {
      this.toast.color = 'success';
      this.toast.header = header;
      this.toast.toastMessage = toastMessage;
      this.toast.showToast = true;
    } );
    bus.$on( 'toast-error', ( header, toastMessage, logMessage ) => {
      this.toast.color = 'danger';
      this.toast.header = header;
      this.toast.toastMessage = toastMessage;
      this.toast.showToast = true;

      // if (logMessage) {
      //   // eslint-disable-next-line no-console
      //   console.log(toastMessage);
      // }
    } );
    bus.$on( 'toast-info', ( header, toastMessage ) => {
      this.toast.color = 'info';
      this.toast.header = header;
      this.toast.toastMessage = toastMessage;
      this.toast.showToast = true;
    } );
  },
  mounted() {
    if( this.$store.state.access_token ) {
      axios.defaults.headers.common.Authorization = this.$store.state.access_token;
      this.$store.dispatch( 'getAndStructureMetadataProperties' );
    }
  },
  components: { Toast },
  data() {
    return{
      loading: false,
      toast:   {
        showToast:    false,
        color:        '',
        header:       '',
        autohide:     3000,
        toastMessage: '',
      },
    };
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';

//makes primary buttons more readable.
.btn-primary:not(.form-control) {
  color: #fff !important;
}

.btn-danger:not(.form-control) {
  color: #fff !important;
}
</style>
