import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { Buffer } from 'buffer';

// Tree-shake lodash for needed functions only.
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import _ from 'lodash';
import router from './router';
import RestClient from './services/RestClient';
import constants from './constants';

Vue.use( Vuex );

const state = {
  isLoading:       false,
  sidebarShow:     'responsive',
  sidebarMinimize: false,
  metaData:        '',
  cachedSearch:    { footer: null, result: null },
  select:          constants.select,
  access_token:    sessionStorage.getItem( 'access_token' ) || null,
  user:
    sessionStorage.getItem( 'user' ) !== null && sessionStorage.getItem( 'user' ) !== undefined ?
      ( JSON.parse( sessionStorage.getItem( 'user' ) )  ) :
      null,
  is_admin:     false
};

const getters = {
  getAccessToken( state ) {
    return state.access_token;
  },
  getUsername( state ) {
    return state.user ? state.user.username ?? null : null;
  },
  isLoggedIn( state ) {
    return state.access_token !== null && state.access_token !== undefined;
  },
  isAdmin( state ){
    return state.user != null && state.user.is_admin;
  },
  isImpactEnabled() {

    //if the environment variable to disable impact is not there then consider it not disabled.
    if(state.user){
   
      const {permissions} = state.user;
      const enable_impact =permissions ? permissions.includes('enable-forge')  : !isNil( process.env.VUE_APP_DISABLE_IMPACT ) ? process.env.VUE_APP_DISABLE_IMPACT.toLowerCase() == 'true': false;
      return enable_impact 
    }
    return false;
  },
  isTimeMachineEnabled() {
    if(state.user){
    const {permissions} = state.user;
    return permissions ? permissions.includes('enable-time_machine') : false
    }
    return false;
  },  
  isCatapultEnabled() {
    if(state.user){
    const {permissions} = state.user;
   return permissions ? permissions.includes('enable-catapult') : false;
   }
   return false;
  },
};

const mutations = {

  auth_success( state, auth_data ) {
    //Store access token in store and sessionStorage.
    state.access_token = auth_data.access_token;
    sessionStorage.setItem( 'access_token', auth_data.access_token );

    //store decoded user data.
    // state.user = auth_data;
    // console.log(auth_data)
    sessionStorage.setItem( 'user', JSON.stringify( auth_data ) );
    state.user = JSON.parse( sessionStorage.getItem( 'user' ) )
  },

  auth_logout( state ) {
    state.access_token = null;
    sessionStorage.removeItem( 'access_token' );

    state.user = null;
    sessionStorage.removeItem( 'user' );

    //close sidebar if opened.
    state.sidebarShow = false;

    //remove stored authentication from header.
    delete axios.defaults.headers.common.Authorization;
    //reroute back to login.
    router.push( '/login' );
  },

  setLoading( state, value ) {
    state.isLoading = value;
  },


  toggleSidebarDesktop( state ) {
    const sidebarOpened = [ true, 'responsive' ].includes( state.sidebarShow );
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },

  toggleSidebarMobile( state ) {
    const sidebarClosed = [ false, 'responsive' ].includes( state.sidebarShow );
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },

  set( state, [ variable, value ] ) {
    state[ variable ] = value;
  },

};

const actions = {

  setLoading( context, value ) {
    context.commit( 'setLoading', value );
  },

  async logout( { commit } ) {
    await commit( 'auth_logout' );
  },

  async login( { commit, state, dispatch }, userCred ) {
    try {
      const loginResponse = await RestClient.doPost( 'api/v1/users/login', {
        username: userCred.username,
        password: userCred.password,
      } );
      const { status } = loginResponse;

      if( status === 200 ) {
        //if no data returned then there was an error
        if( isNil( loginResponse.data ) ) {
          return loginResponse;
        }
       
        const { access_token, expires_in } = loginResponse.data;
        
        const is_expired = Date.now > new Date( expires_in )
        //if the token is expired do not authenticate
        if(is_expired){
          throw new Error('Token is expired.')
        }
        axios.defaults.headers.common.Authorization = await access_token;
        await commit( 'auth_success', { access_token, ...loginResponse.data } );
        await dispatch( 'getAndStructureMetadataProperties' );

        router.push( '/' );
      }
    } catch( err ) {
      console.log( err );
      //handle the error here.
      return err;
    }
  },

  async addAdjustedMetaData( { state, dispatch }, adjusted_metaDatas ) {
    //before adding new adjusted metas, remove any previous _adjusted metas.
    state.accordionData = state.accordionData.filter( prop => prop.path !== '_adjusted' );
    for await ( const [ index, adjusted_meta ] of adjusted_metaDatas.entries() ) {
      let _adjusted_array = adjusted_meta.path.split( '.' )//.splice( 1 ).join( '.' );
      //adjusted fields are labeled as such *._adjusted.* for example recipient._adjusted.age for recipient.age
      _adjusted_array.splice(1, 0, '_adjusted');
      const _adjusted = _adjusted_array.join('.');

      const { adjusted_rule_id } = adjusted_meta;
      //get type of adjusted field from metaData
      const original_property = _.get( state.metaData, adjusted_meta.path );
      let _type = null;
      let enumValues = null;
      let children = null;
      if( original_property ) {
        _type = original_property.type;
        enumValues = original_property.enumValues;
        children = original_property.children;
      }
      const newProperty = {
        id:       _adjusted + index,
        name:     `${_adjusted.split( '.' ).join( '_' )}`,
        path:     _adjusted,
        _type,
        enumValues,
        children: children ?? null,
      };
      const _adjusted_property = {
        id:         `_adjusted${index}`,
        name:       `_adjusted (${adjusted_rule_id})`,
        path:       '_adjusted',
        _type:      null,
        enumValues: null,
        children:   [ newProperty ],
      };

      state.accordionData.push( _adjusted_property );
    }
  },

  async getPropertyTreeObject( { state, dispatch }, Path = null ) {
    try {
      const objArray = [];
      let keys = null;
      let path = Path;
      let currentObject;
      if( isNil( path ) ) {
        currentObject = state.metaData;
        path = '';
      } else {
        currentObject = _.get( state.metaData, path );
      }
      keys = Object.keys( currentObject );

      if( !isNil( keys ) && isArray( keys ) && keys.length >= 1 ) {
        for( const [ index, child ] of keys.entries() ) {
          const currentPath =
            path === null || path === undefined || _.isEmpty( path ) ?
              `${child}` :
              `${path}.${child}`;
          let hasChildren = false;
          let subObject = _.get( state.metaData, currentPath, {} );
          if( subObject.type ) {
            subObject = subObject.type;
          }
          const subkeys = subObject ?? null;
          hasChildren = !_.isString( subkeys ) && Object.keys( subkeys ).length > 0;
          let children = null;
          if( hasChildren ) {
            children = await dispatch( 'getPropertyTreeObject', currentPath );
          }
          const _type = hasChildren ? null : subObject;

          const newProperty = {
            id:   child + index,
            name: _.isNumber( child ) && child.name ? child.name : child,
            path: currentPath,
            _type,

            enumValues: _.get( state.metaData, currentPath ).enumValues ?? null,
            children:   children ?? null,
          };

          objArray.push( newProperty );
        }
      }

      //filter by properties that contain subproperties first.
      return objArray.sort( ( a, b ) => b.children !== null );
    } catch( err ) {
      console.log( 'Error getting propertyTree Object' );
      console.log( err.message );
      console.log( err );
    }
  },
  async getAccordionData( { commit, state, dispatch } ) {
    const accordionData = await dispatch( 'getPropertyTreeObject' );
    const modifiedAccordionData = _.clone( accordionData );
    const metadata_spreadable_properties =
        constants.metadata_spreadable_properties;

    //traverse the child of each parent on root level.
    for( const prop_original of modifiedAccordionData ) {
      const spreadable_properties = prop_original.children.filter( child => metadata_spreadable_properties.includes( child.name ) );
      //if one of them contains children that is defined as a spreadable object (in constants.metadata_spreadable_properties)
      if( spreadable_properties.length > 0 ) {
        _.remove( prop_original.children, currentObject => metadata_spreadable_properties.includes( currentObject.name ) );
        //for each of the spreadable properties, spread their children to their parent levels.
        for( const prop of spreadable_properties ) {
          //spread the children of the spreadable property to their parent.
          prop_original.children.push( ...prop.children );
          //remove original property that contained the now spreaded data.
          prop.children = prop.children.filter( p => !metadata_spreadable_properties.includes( p.name ) )
        }
      }
    }
    //sort properties before returning
    for(let i=0; i<modifiedAccordionData.length; i++ ){
      if(modifiedAccordionData[i].children){
     await modifiedAccordionData[i].children.sort((a,b)=> a.children)
      }
    }
    return modifiedAccordionData;
  },
  async getAndStructureMetadataProperties( { commit, state, dispatch } ) {
    try {
      //get metadata from backend.
      let response= await
       RestClient.doGet( `/api/v1/allocation/metadata` )
      //store metaData object.
      state.metaData = response;
      //filter out any empty objects.
      Object.keys( state.metaData ).forEach( meta => {
        if( Object.keys( state.metaData[ meta ] ).length === 0 ) {
          delete state.metaData[ meta ];
        }
      } );
      state.accordionData = await dispatch( 'getAccordionData' );
    } catch( err ) {
      console.log( `Error Getting Metadata: ${err.message}` );
      console.log( err );
    }
  },
};

export default new Vuex.Store( {
  state,
  getters,
  mutations,
  actions,
} );
