/* eslint-disable no-console */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import CoreuiVue from '@coreui/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VueGoodTablePLugin from 'vue-good-table';
import App from './App';
import router from './router';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import '../validationRules';

import 'vue-good-table/dist/vue-good-table.css';
import vuetify from './plugins/vuetify';

// Install vee-validate components globally
Vue.component( 'ValidationObserver', ValidationObserver );
Vue.component( 'ValidationProvider', ValidationProvider );
Vue.config.performance = true;
Vue.use( CoreuiVue );
Vue.use( VueGoodTablePLugin );
Vue.prototype.$log = console.log.bind( console );

export const bus = new Vue();

export default new Vue( {
  el:       '#app',
  router,
  store,
  icons,
  template: '<App/>',
  vuetify,

  components: {
    App,
  },
} );
