/**
 * Application Base REST API Service.
 *
 * @copyright Shore
 */

import axios from 'axios';
import { bus } from '../main';
import store from '../store';
import Constants from '../constants';
import router from '../router';

const successHandler = async response => response;

/**
 * Default error handler interceptor
 * Http errors 500, 401, 403 and 404 are managed from here.
 * All other errors are send back to the application.
 *
 * @param {AxiosError } error
 * @returns
 */
const errorHandler = async error => {
  const { response } = error;

  if( response ) {
    const { status } = response;
    switch( status ) {
      case 400:
        bus.$emit( 'toast-error', response.data ? response.data.error : 'User Error', response.data ? response.data.message : response );
        break;
      case 401:
        bus.$emit( 'toast-error', 'Not Found', response.data ? response.data.error : response );
        //if user is not already in login then logout.
        if( router.currentRoute.path !== '/login' ) {
          store.dispatch( 'logout' );
        }
        // Router.push('/logout');
        break;
      case 404:
        // Router.push('/404');
        break;
      case 500:
        // Router.push('/500');
        break;
      default:
        break;
    }

    return Promise.reject( response );
  }

  return Promise.reject( error );
};

/**
 * Set Authentication Token by default if exists for all outgoing Requests
 *
 * @param {AxiosRequestConfig} config
 * @returns
 */
const requestHandler = config => {
  const token = store.getters.getAccessToken;
  const newConfig = { ...config };

  if( token ) {
    newConfig.headers = {
      ...newConfig.headers,
      authorization: `Bearer ${token}`,
    };
  }

  return newConfig;
};

const RestClient = () => {
  const service = axios.create( {
    baseURL: Constants.BACKEND_URL,
  } );

  service.interceptors.response.use( successHandler, errorHandler );
  service.interceptors.request.use( requestHandler, errorHandler );

  /**
   * Default Get Service function
   *
   * @param {string} url Base URL
   * @param {QueryParams} params Query Parameters
   *
   * @returns {Promise<Object>}
   */
  const doGet = async ( url, params = null ) => {
    const response = params ?
      await service.get( url, { params: params } ) :
      await service.get( url );

    return response.data;
  };

  /**
   * Default Post Service function
   *
   * @param {string} url
   * @param {Object} payload
   *
   * @returns {Promise<Object>}
   */
  const doPost = async ( url, payload ) => {
    const response = await service.post( url, payload );
    return response;
  };

  /**
   * Default Put Service function
   *
   * @param {string} url
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  const doPut = async ( url, payload ) => {
    const response = await service.put( url, payload );
    return response;
  };

  /**
   * Default Delete Service function
   *
   * @param {string} url
   *
   * @returns {Promise<void>}
   */
  const doDelete = async url => {
    const response = await service.delete( url );
    return response;
  };

  /**
   * Default Patch Service function
   *
   * @param {string} url
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  const doPatch = async ( url, payload ) => {
    const response = await service.patch( url, payload );
    return response;
  };

  return{
    doGet,
    doPost,
    doPut,
    doDelete,
    doPatch,
  };
};

const client = RestClient();
export default client;
