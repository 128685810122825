var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: _vm.fade ? "fade" : null, appear: true } },
    [
      _vm.isShowed
        ? _c(
            "div",
            {
              class: _vm.toastClasses,
              attrs: {
                role: "alert",
                "aria-live": "assertive",
                "aria-atomic": "true",
              },
            },
            [
              _vm.header !== undefined || _vm.$slots.header
                ? _c(
                    "div",
                    { staticClass: "toast-header" },
                    [
                      _vm._t("header", function () {
                        return [
                          _c("strong", { staticClass: "mfe-auto" }, [
                            _vm._v(_vm._s(_vm.header)),
                          ]),
                        ]
                      }),
                      _vm.closeButton
                        ? _c("CButtonClose", {
                            staticClass: "mfs-2 mb-1",
                            on: {
                              click: function ($event) {
                                return _vm.close()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c("div", { staticClass: "toast-body" }, [_vm._t("default")], 2),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }